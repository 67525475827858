import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/nulogy.design/nulogy.design/src/components/MarkdownRenderer.js";
import { Table, IconicButton } from "@nulogy/components";
import { config } from "../../playgrounds/table";
import { tableRowProps, tableColumnProps, cellRendererArgs, headerFormatterArgs } from "../../playgrounds/table-props";
import { rows, columns } from "../../examples/table/data";
import { STORYBOOK_LINK } from "../../components/CONSTANTS";
export const StoryBookLink = () => <a href={STORYBOOK_LINK}>Storybook</a>;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Playground = makeShortcode("Playground");
const Example = makeShortcode("Example");
const PropsTable = makeShortcode("PropsTable");
const layoutProps = {
  StoryBookLink,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`Table`}</h1>
    <p>{`Tables are used for displaying columns and rows of data.`}</p>
    <Playground {...config} mdxType="Playground" />
    <h2>{`Variations`}</h2>
    <h3>{`Customizing cell display`}</h3>
    <p>{`A custom component can be implemented using a `}<inlineCode parentName="p">{`cellFormatter`}</inlineCode>{` (to maintain the existing cell styles) or `}<inlineCode parentName="p">{`cellRenderer`}</inlineCode>{` (for completely custom styles).`}</p>
    <p>{`Similarly headers can be customized using the `}<inlineCode parentName="p">{`headerFormatter`}</inlineCode>{` function props. See `}<StoryBookLink mdxType="StoryBookLink" />{` for other examples of implementing different custom components.`}</p>
    <Example componentName="table" scope={{
      Table,
      rows,
      columns,
      IconicButton
    }} example="CustomCells" mdxType="Example" />
    <h3>{`ColumnWidths`}</h3>
    <p>{`A width for a column can be set (as actual size or percentage) inside the column data.`}</p>
    <Example componentName="table" scope={{
      Table,
      rows,
      columns
    }} example="ColumnWidths" mdxType="Example" />
    <h3>{`Compact Styling`}</h3>
    <p>{`The table can be set to use compact styling which decreases the paddings when the compact prop is set to true.`}</p>
    <Example componentName="table" scope={{
      Table,
      rows,
      columns
    }} example="CompactStyling" mdxType="Example" />
    <h3>{`Footer`}</h3>
    <p>{`A footer can be added to the table by adding an array of rows to the `}<inlineCode parentName="p">{`footerRows`}</inlineCode>{` prop.`}</p>
    <Example componentName="table" scope={{
      Table,
      rows,
      columns
    }} example="FooterRows" mdxType="Example" />
    <h3>{`StickyHeader`}</h3>
    <p>{`The table header can remain fixed to the top of the table when scrolling by setting the prop stickyHeader to true.`}</p>
    <Example componentName="table" scope={{
      Table,
      rows,
      columns
    }} example="StickyHeader" mdxType="Example" />
    <h3>{`Pagination`}</h3>
    <p>{`Setting `}<inlineCode parentName="p">{`rowsPerPage`}</inlineCode>{` on the Table will add a `}<a parentName="p" {...{
        "href": "/pagination"
      }}>{`Pagination`}</a>{` component to the table. A maximum of the specified `}<inlineCode parentName="p">{`rowsPerPage`}</inlineCode>{` will be shown on each page.`}</p>
    <p>{`Providing a function to `}<inlineCode parentName="p">{`onPageChange`}</inlineCode>{` will allow tracking of the current page number. It is fired whenever the page changes and takes in the current page number as an argument.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "/pagination"
      }}>{`Pagination`}</a>{` and Table components can also be used together to support server-side pagination or other custom behaviour. An example of such an implementation can be found in `}<StoryBookLink mdxType="StoryBookLink" />{`.`}</p>
    <Example componentName="table" scope={{
      Table,
      rows,
      columns
    }} example="Pagination" mdxType="Example" />
    <h3>{`Headings`}</h3>
    <p>{`Headings that span the full width of a row can be added within the table's rows. To add a heading add a row with a key of `}<inlineCode parentName="p">{`heading`}</inlineCode>{` The appearance of the heading can be customized by adding a `}<inlineCode parentName="p">{`cellRenderer`}</inlineCode>{` to the row. See an example in `}<StoryBookLink mdxType="StoryBookLink" />{`.`}</p>
    <h3>{`Filtering`}</h3>
    <p>{`Filtering can be implemented by passing filtered rows to the rows prop of the table. See an example of filtering in `}<StoryBookLink mdxType="StoryBookLink" />{`.`}</p>
    <h3>{`Sorting`}</h3>
    <p>{`Sorting can be implemented using the headerFormatter to pass a SortingHeader component or another custom header to the column that should be sortable. See an example of the complete implementation with table in `}<StoryBookLink mdxType="StoryBookLink" />{`.`}</p>
    <h3>{`Selectable Rows`}</h3>
    <p>{`Setting `}<inlineCode parentName="p">{`hasSelectableRows`}</inlineCode>{` on the Table will add a column of checkboxes to the table so that rows can be selected by the user. Using the checkbox in the head of the table will toggle the selection of all rows.`}</p>
    <p>{`A `}<inlineCode parentName="p">{`keyField`}</inlineCode>{` should be specified to provide unique ids for rows (by default the `}<inlineCode parentName="p">{`keyField`}</inlineCode>{` will be "id" and expect a property of id in the row objects).`}</p>
    <Example componentName="table" scope={{
      Table,
      rows,
      columns
    }} example="SelectableRows" mdxType="Example" />
    <h3>{`Expandable Rows`}</h3>
    <p>{`Setting `}<inlineCode parentName="p">{`hasExpandableRows`}</inlineCode>{` and providing `}<inlineCode parentName="p">{`expandedContent`}</inlineCode>{` on a row will add a button that can be used to expand and collapse `}<inlineCode parentName="p">{`content.expandedContent`}</inlineCode>{` should return a React node that should be rendered when the row is expanded.`}</p>
    <p>{`A `}<inlineCode parentName="p">{`keyField`}</inlineCode>{` should be specified to provide unique ids for rows (by default the `}<inlineCode parentName="p">{`keyField`}</inlineCode>{` will be "id" and expect a property of id in the row objects).`}</p>
    <Example componentName="table" scope={{
      Table,
      rows,
      columns
    }} example="ExpandableRows" mdxType="Example" />
    <h2>{`Props`}</h2>
    <PropsTable propsConfig={config.props} mdxType="PropsTable" />
    <h2>{`Column Options`}</h2>
    <PropsTable propsConfig={tableColumnProps} mdxType="PropsTable" />
    <h2>{`Row Options`}</h2>
    <p>{`Rows should have keys corresponding to the dataKeys provided in the columns. In addition, there are a few extra keys used by the table that can be provided to each row`}</p>
    <PropsTable propsConfig={tableRowProps} mdxType="PropsTable" />
    <h2>{`CellRenderer / CellFormatter Argument Type`}</h2>
    <p>{`Use cellFormatter to maintain the styles within the cell while providing a custom component or string. Use cellRenderer when using completely custom styles.`}</p>
    <PropsTable propsConfig={cellRendererArgs} mdxType="PropsTable" />
    <h2>{`HeaderFormatter Argument Type`}</h2>
    <p>{`Use headerFormatter to provide a custom header component. Styles on the header cell will be maintained.`}</p>
    <PropsTable propsConfig={headerFormatterArgs} mdxType="PropsTable" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      